import { fetchAPI } from "../utils/api";
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import { StrapiResponse, GreenhouseResponse, GreenhouseJob, PageResponse, Page, StrapiComponent, StrapiMetaResponse, HeaderData, ArticleResponse, ResourceData, StrapiMetaData, FooterResponse, StrapiSingleResponse, FooterData } from "../utils/types";
import type { NextPage } from 'next'
import { NextSeo } from "next-seo";
import Link from "next/link";
import WebsiteLayout from '../components/layout/WebsiteLayout'
import Sections from '../components/sections'
import NotFound from './NotFound'
import LoadingScreen from './LoadingScreen'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

interface PageProps {
    page: Page,
    sections: StrapiComponent[] | null,
    metaData: StrapiMetaResponse,
    articles: Array<ResourceData>,
    preview: boolean,
    footer: FooterData | null,
    jobs: Array<GreenhouseJob> | null
}

const header = {
    id: 1,
    __component: "component.header",
    title: "",
    subtitle: "",
    theme: "green",
    headerType: "hidden"
} as HeaderData

function getMetaDateFields(page: Page) {
    return {
        title: page.metaData?.metaTitle || page.header.title || page.title,
        description: page.metaData?.metaDescription || page.header.subtitle || page.title
    }

}

const DynamicPage: NextPage<PageProps> = ({ page, sections, metaData, articles, preview, footer, jobs }) => {

    const router = useRouter()

    if (!router.isFallback && !sections?.length) {
        const errorPage = <NotFound />
        return (
            <WebsiteLayout
                navbarTheme={'white'}
                header={header}
                preview={preview}
                secondaryNavbar={undefined}
            >
                {errorPage}
            </WebsiteLayout>
        )
    }

    if (router.isFallback) {
        return (
            <LoadingScreen />
        )
    }
    
    let slug = (page.slug as [] || [""]).join('/')

    const meta = getMetaDateFields(page)

    return (
        <>
            <NextSeo
                title={meta.title}
                description={meta.description}
                openGraph={{
                    title: meta.title,
                    description: meta.description,
                    images: page.metaData?.shareImage?.data ? [
                        {
                            url: `${ImageEndpoint}${page.metaData.shareImage.data.attributes.url}`
                        }
                    ] : undefined
                }}
            />
            <WebsiteLayout
                disableNavbar={page.disableNavbar ? page.disableNavbar : false}
                navbarTheme={page.navbarTheme ? page.navbarTheme : 'white'}
                header={page.header ? page.header : header}
                preview={preview}
                secondaryNavbar={page.secondaryNavbar ? page.secondaryNavbar : undefined}
                footer={footer || null}
            >
                <Sections slug={slug} jobs={jobs} articles={((slug === 'library' || slug === 'blog' || slug === 'resources' || slug === 'library/all-articles') && articles.length) ? articles : undefined} sections={sections} />
            </WebsiteLayout>
        </>
    )
}

export const getPageData = async (slug: string, preview: boolean, token: string) => {

    // extract out of getStaticProps so it can be reused in protected pages under 'internal'

    const query = {
        filters: {
            slug: {
                // TODO figure out if there is a case where slug isn't passed as string as per type def.
                $eq: slug
            }
        },
        populate: {
            requiresRole: '*',
            header: {
                populate: '*'
            },
            metaData: {
                populate: '*'
            },
            secondaryNavbar: {
                populate: '*'
            },
            sections: {
                populate: {
                    actionsList: {
                        populate: '*'
                    },
                    dataPoints: {
                        populate: '*'
                    },
                    icon: {
                        url: true,
                        alternativeText: true
                    },
                    logo: {
                        url: true,
                        alternativeText: true
                    },
                    bgImage: {
                        url: true,
                        alternativeText: true
                    },
                    bgImageBlockOne: {
                        url: true,
                        alternativeText: true
                    },
                    bgImageBlockTwo: {
                        url: true,
                        alternativeText: true
                    },
                    bgImageDesktop: {
                        url: true,
                        alternativeText: true
                    },
                    bgImageMobile: {
                        url: true,
                        alternativeText: true
                    },
                    callToAction: {
                        populate: '*'
                    },
                    ctaBlockOne: {
                        populate: '*'
                    },
                    ctaBlockTwo: {
                        populate: '*'
                    },
                    steps: {
                        populate: '*'
                    },
                    organisations: {
                        populate: '*'
                    },
                    team_members: {
                        populate: '*'
                    },
                    milestoneList: {
                        populate: '*'
                    },
                    resources: {
                        populate: '*'
                    },
                    mostPopular: {
                        populate: '*'
                    },
                    links: {
                        populate: '*'
                    },
                    button: {
                        populate: '*'
                    },
                    promo_1: {
                        populate: '*'
                    },
                    promo_2: {
                        populate: '*'
                    },
                    image: {
                        url: true,
                        alternativeText: true
                    },
                    articleFilters: {
                        populate: '*'
                    },
                    foundersList: {
                        populate: '*'
                    },
                    modules: {
                        populate: '*'
                    },
                    items: {
                        populate: '*'
                    },
                    formInputs: {
                        populate: '*'
                    },
                    submitButton: {
                        populate: '*'
                    },
                    childElement: {
                        populate: '*'
                    },
                    featuredImage: {
                        url: true,
                        alternativeText: true
                    },
                    tabs: {
                        populate: '*'
                    },
                    teams: {
                        populate: '*'
                    },
                    contentImage: {
                        url: true,
                        alternativeText: true
                    },
                    slides: {
                        populate: '*'
                    },
                    media: {
                        populate: '*'
                    },
                    quotes: {
                        populate: '*'
                    }
                }
            }
        }
    }

    const articleQuery = {
        filters: {
            type: {
                $in: ['Blog', 'Video', 'Audio']
            },
        },
        populate: {
            metaData: {
                populate: '*'
            },
            thumbnail: {
                populate: '*'
            },
            authors: {
                populate: '*'
            },
            category: {
                populate: '*'
            }
        },
        pagination: {
            start: 0,
            limit: 150
        }
    }

    const FooterQuery = {
        populate: {
            pageLinks: {
                populate: '*'
            },
            socials: {
                populate: '*'
            }
        }
    }

    const footerResponse = await fetchAPI<StrapiSingleResponse<FooterResponse>>('footer', FooterQuery, token)

    const response = await fetchAPI<StrapiResponse<PageResponse>>('pages', query, token)

    let articles = null

    if (slug === 'library' || slug === 'blog' || slug === 'resources' || slug === 'library/all-articles') {
        const responseArticles = await fetchAPI<StrapiResponse<ArticleResponse>>('resources', articleQuery, token)
        articles = responseArticles.data.map(item => item.attributes)
    }

    let jobs = null

    if (slug === 'join-us' || slug === 'join-us/jobs') {
        const jobResponse = await fetch('https://boards-api.greenhouse.io/v1/boards/polymathventures/jobs/?content=true')
        const jobsInfo = await jobResponse.json() as GreenhouseResponse<GreenhouseJob>
        jobs = jobsInfo.jobs
    }
    
    // TODO handle lenght != 1
    const pageResponse = response.data[0];

    if (!pageResponse) {
        return { props: {} }
    }

    const page = {
        ...pageResponse.attributes,
        slug: pageResponse.attributes.slug.split('/'),
        id: pageResponse.id,
    } as Page

    const footer = {
        pageLinks: footerResponse.data.attributes.pageLinks,
        socials: footerResponse.data.attributes.socials,
    } as FooterData

    const sections = pageResponse.attributes.sections || null

    const metaData = response.meta

    if (slug === 'library' || slug === 'blog' || slug === 'resources' || slug === 'library/all-articles') {
        return {
            props: {
                page,
                sections,
                metaData,
                articles,
                preview,
                footer
            }
        }
    }

    if (slug === 'join-us' || slug === 'join-us/jobs') {
        return {
            props: {
                page,
                sections,
                metaData,
                preview,
                footer,
                jobs
            }
        }
    }

    return {
        props: {
            page,
            sections,
            metaData,
            preview,
            footer
        }
    }


}


export const getStaticProps: GetStaticProps = async (context) => {

    let slug = (context.params?.slug as [] || [""]).join('/')
    slug = slug.length ? slug : 'home'

    const preview = context.preview || false

    return getPageData(slug, preview, '')
}


// TODO get locale from context
// { locales: ..., defaultLocale: ... }
export async function getStaticPaths(locale: any) {
    let results = await fetchAPI<StrapiResponse<PageResponse>>('pages')

    let paths = results.data.map((page: PageResponse) => {
        return {
            params: {
                ...page.attributes,
                slug: page.attributes.slug.split('/'),
                id: page.id
            } as Page,
        }
    })

    return {
        paths: paths,
        fallback: true
    };
}

export default DynamicPage